import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import emailjs from '@emailjs/browser'
import AOS from 'aos';
import Headroom from 'react-headroom';
import './home.css';
import logo from '../../images/logo-nobg.png';
import seminar from '../../images/seminar.jpg';
import teamBuilding from '../../images/team_building.jpg';
import meeting from '../../images/meeting-ladies.jpg';
import team from '../../images/team-building.png';
import group from '../../images/conference-Photoroom.png';
import train from '../../images/train.svg';
import consult from '../../images/consult.svg';
import wim from '../../images/wim.png';
import gm from '../../images/gm.jpg';
import kemu from '../../images/client4.png';
import aiesec from '../../images/client5.png';
import kim from '../../images/client6.png';
import inspired from '../../images/client7.png';
import client8 from '../../images/client8.png';
import meru from '../../images/client9.png';
import mck from '../../images/client10.png';
import mod from '../../images/client11.png';
import hymnlets from '../../images/hymnlets-nobg.png';
import zetech from '../../images/zetech.png'

function Home() {
    const [ isVisible, setIsVisible ] = useState(false);
    const handleMenuVisibility = () => {
        setIsVisible(!isVisible);
    }

   useEffect(() => {
     AOS.init({duration: 2000});
   }, [])

    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm("service_oplvpjc", 'template_ozdvktr', form.current, {
          publicKey: 'ifOYZCN82lI8EqBsQ',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            alert('Email Sent Successfully!')
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };
   
  return (
    <div className="container">
        {/* home page */}
        <section id="home">
            <div className="home-cover">
            <Headroom>
                <div className="header">
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    {/* desktop nav */}
                    <div className= 'nav-bar'>
                        <ul>
                            <li><Link to='#home' className='active'>Home</Link></li>
                            <li><Link to='#about'>About</Link></li>
                            <li><Link to='#services'>Services</Link></li>
                            <li><Link to='#contact'>Contact</Link></li>
                            <li><Link to='#clients'>Clients</Link></li>
                            <li><Link to='/inclusivity'>Inclusivity</Link></li>
                            <li><Link to='#'>Gallery</Link></li>
                        </ul>   
                    </div>

                    {/* mobile nav */}
                    <div className= {isVisible ? 'nav-bar-mobile' : 'nav-bar-hide'}>
                        <ul>
                            <li><Link to='#home' className='active'>Home</Link></li>
                            <li><Link to='#about'>About</Link></li>
                            {/* <li><Link to='#about-desc'>More About</Link></li> */}
                            <li><Link to='#services'>Services</Link></li>
                            {/* <li><Link to='#uniqueness'>Uniqueness</Link></li> */}
                            <li><Link to='#contact'>Contact</Link></li>
                            <li><Link to='#clients'>Clients</Link></li>
                            <li><Link to='#'>Gallery</Link></li>
                        </ul>   
                    </div>
                    <a href="tel:+254722332688"><i class="bi phone bi-telephone-fill"> +254722332688</i></a>

                    <div className="menu" onClick={handleMenuVisibility}>
                        <i class="bi bi-list"></i>
                    </div>
                    <div className="socials">
                        <a href="tel:+254722332688"><i class="bi phone bi-telephone-fill"> +254722332688</i></a>
                        <a href="https://www.facebook.com/profile.php?id=100057700770131&mibextid=ZbWKwL" target='_blank' rel="noreferrer"><i class="bi bi-facebook"></i></a>
                        
                        <i class="bi bi-instagram"></i>
                        <i class="bi bi-linkedin"></i>
                    </div>
                </div>
            </Headroom>
                <div className="home-row">                    
                    <div className="home-col">
                        <div className="no1" data-aos = "flip-up">
                            <h1>Training & Consulting</h1>
                        </div>
                        <div className="home-desc">
                            <p>Welcome to <strong>Sharepoint Training and Consultancy Ltd</strong>, Kenya's leading training and consultancy firm. We help organizations stay ahead by providing comprehensive training and consultancy services. Driven by our clients' success stories, we align our programs with Kenya's Vision 2030 and the Millennium Development Goals (MDGs), ensuring a future-focused approach. Our corporate training solutions are designed to foster overall business growth, with specialized expertise in MSME training to support businesses in enhancing their performance and driving sustainable growth.
                            </p>
                        </div> 
                        <Link to='#contact'><button className='learn-more' id='learn-btn'>GET IN TOUCH</button></Link>

                    </div> 
                    <div className="home-col">
                        <div className="home-img" data-aos = "flip-left">
                            <img src={seminar} alt="Seminar" />
                        </div>
                        <div className="whatsapp">
                            <div class="elfsight-app-db07333c-3bf8-4746-afcb-55fbc3284c62" data-elfsight-app-lazy></div>
                            {/* <a href="https://wa.link/7bjr77" target="_blank" rel="noopener noreferrer">
                                <i class="bi bi-whatsapp"></i>
                            </a> */}
                        </div>
                    </div>
                </div>       
            </div>
        </section>

        {/* about */}
        <section id="about">
            <div className="about-cover">
            <h1>ABOUT SHAREPOINT</h1>
                <div className="home-row">
                    <div className="about-col" data-aos="fade-up">
                        <h2>100+</h2>
                        <p>Trained Clients</p>
                    </div>
                    <div className="about-col" data-aos="fade-down">
                        <h2>20+</h2>
                        <p>Companies</p>
                    </div>
                    <div className="about-col" data-aos="fade-left">
                        <h2>5+</h2>
                        <p>Countries</p>
                    </div>
                    <div className="about-col" data-aos="fade-right">
                        <h2>5+</h2>
                        <p>Certification</p>
                    </div>
                </div>
                <div className="about-row">
                    <div className="about-col2">
                        <img src={meeting} alt="Team Building" />
                    </div>
                    <div className="about-col2"  data-aos = "flip-left">                        
                        <p>
                        Sharepoint Training and Consultancy Ltd is a full-service training and consultancy firm, established in 2007 and incorporated as a limited company in April 2013. Our mission is to provide businesses with cutting-edge training and consultancy services that unlock growth opportunities and ensure sustainability. We pride ourselves on offering business-oriented solutions, linking established and emerging enterprises with corporate partners for collaboration and support. Our goal is to deliver sustainable solutions that empower businesses to thrive in an ever-evolving marketplace.
                        </p>
                        <div className="learn-more">
                            <h2><Link to="#about-desc">Learn More</Link></h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* about-desc */}
        <section id="about-desc">  
            <div className="about-desc-cover">
                <h1>GET TO KNOW US BETTER</h1>
                <div className="home-row">
                    <div className="about-desc-col" data-aos = "flip-right">
                            <img src={teamBuilding} alt="Team building activity" />
                        <h2>Our Vision</h2>
                        <p>
                            Combining leading business strategy and Consultancy expertise, to develop eﬃcient and eﬀective solutions that keep organizations one-step ahead.
                        </p>
                    </div>

                    <div className="about-desc-col" data-aos = "flip-right">
                        <img src={group} alt="Group" />
                        <h2>Our Mission</h2>
                        <p>
                        Our mission at Sharepoint Training and Consultancy Ltd (SPT) is to deliver innovative and impactful leadership training, fostering healthy leadership practices across both the public and private sectors throughout Africa.
                        </p>
                    </div>

                    <div className="about-desc-col" data-aos = "flip-right">
                        <img src={team} alt="Team building activity" />
                        <h2>Core Values</h2>
                        <p>
                        We are committed to delivering customer satisfaction by fostering a welcoming environment, while ensuring integrity, innovation, teamwork, efficiency, and reliability in our operations. 
                        <Link to='/core-values' target='_blank'>Read More</Link>                 
                        </p>
                    </div>
                </div>
            </div>          
        </section>

        {/* services */}
        <section id="services">    
            <div className="services-cover">
                <h1>SERVICES</h1>

                <div className="home-row">
                    <div className="services-col2"  data-aos = "fade-out">
                        <img src={train} alt="Training" />
                    </div>
                    <div className="services-col2">
                        <h2>Training</h2>
                        <p>  At Sharepoint Training & Consultancy, we offer a comprehensive range of training programs tailored to enhance individual and organizational performance. Our expertise includes Leadership & Management, Customer Experience, Sales & Marketing, MSME & Entrepreneurship, Team Building, Strategic Management, Financial Literacy, Business Growth & Development, Supervisory Skills, Emotional Intelligence, and Risk Management. Each program is designed to empower participants with practical skills and knowledge, driving business success and fostering sustainable growth. </p>
                        <Link to='/training' target='_blank'>Read More</Link>                     
                    </div>
                </div>
                <div className="home-row">
                    <div className="services-col1" data-aos = "flip-down">
                        <h2 id='consult'>Consultancy</h2>
                        <p>At Sharepoint Training & Consultancy, we specialize in sourcing business information for both local and international investors in managerial areas and liaison matters both ways. Every type and size of an organization is now dependent on its human capital development to improve eﬃciency, reduce costs, increase customer base, and improve customer retention</p>
                        <Link to='/consultancy' target='_blank'>Read More</Link>
                    </div>
                    <div className="services-col1">
                        <img src={consult} alt="Consulting" />
                    </div>
                </div>

                <div className="home-row">
                    <div className="services-col2"  data-aos = "fade-out">
                        <img src={train} alt="Training" />
                    </div>
                    <div className="services-col2">
                        <h2>More Services</h2>
                        <p> Other services we offer include: <br />
                            - Entrepreneurship and Economic Empowerment <br />
                            - Strategic Planning <br />
                            - Project Management <br />
                            - Banking and Finance
                        </p>
                        <Link to='/services' target='_blank'>Read More</Link>                     
                    </div>
                </div>
            </div>        
        </section>

        {/* contact */}
        <section id="contact" data-aos = "fade-in">
            <div className="contact-cover">
                <div className="top-cover">
                    <h1>GET IN TOUCH</h1>
                    <p>
                        We endeavor to render you seamless services and we look forward to a mutually beneﬁcial relationship.
                        We’ll be glad to hear from you.
                    </p>
                    <div className="contact-info">
                        <div className="home-row">
                            <div className="contact-col">
                                <div className="contact-inner-col" data-aos = "flip-left">
                                    <h1>Talk to us:</h1>
                                    {/* phone */}
                                    <div className="content">
                                        <div className="icon">
                                            <i class="bi bi-telephone-fill"></i>
                                        </div>
                                        <div className="info">
                                            <ul>
                                                <li>+254 722 332688</li>
                                                <li>+254 722 840216</li>
                                                <li>+254 706 918413</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* email */}
                                    <div className="content">
                                        <div className="icon">
                                            <i class="bi bi-envelope-fill"></i>
                                        </div>
                                        <div className="info">
                                            <ul>
                                                <li>info@sharepointtraining.co.ke</li>
                                                <li>fkaithia@sharepointtraining.co.ke</li>
                                                <li>bmburugu@sharepointtraining.co.ke</li>
                                            </ul>
                                        </div>
                                    </div>                                 
                                    

                                    {/* phone */}
                                    <div className="content">
                                        <div className="icon">
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div className="info">
                                            <p>
                                                Brunei House- Witu Road, Nairobi
                                            </p>
                                        </div>
                                    </div>  
                                    <div className="content">
                                        <div className="icon">
                                            <i class="bi bi-mailbox"></i>
                                        </div>
                                        <div className="info">
                                            <p>
                                                Postal address: <br />
                                                P.O. Box 4074-00506 <br /> Nyayo Stadium

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-col">
                                
                                <form ref={form} onSubmit={sendEmail}>
                                    <label htmlFor="name">Your Names:</label>
                                    <input type="text" name="name" id="name" placeholder='John Doe'/>

                                    <label htmlFor="email">Your Email:</label>
                                    <input type="email" name="email" id="email" placeholder='johndoe@example.com'/>

                                    <label htmlFor="subject">Subject:</label>
                                    <input type="text" name="subject" id="subject" placeholder='Training details'/>

                                    <label htmlFor="message">Booking/Inquiry:</label>
                                    <textarea name="message" id="message" cols="10" rows="5" placeholder='Enter Booking details or send an inquiry'></textarea>

                                    <button type="submit" id='btn'>Send Message</button>
                                </form>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="botton-cover">                   
                </div>
            </div>
        </section>

        {/* clients */}
        <section id="clients">
            <h1>OUR CLIENTS</h1>
            {/* <div className="clients-cover">
                <img src={teamBuilding} alt="Office Space" />              
            </div> */}
            <div className="client-row">
                <div className="client-col1" data-aos = "flip-down">
                    <a href="https://www.wim.co.ke/" target='_blank' rel="noreferrer" ><img src={wim} alt="Women in the Marketplace" /></a>
                                        
                </div>
                <div className="client-col1" data-aos = "flip-down">
                    <a href="https://www.gm.com" target='_black' rel='noreferrer'><img src={gm} alt="General Motors" /></a>
                </div>
                <div className="client-col1" data-aos = "flip-down"> 
                    <a href="https://zetech.ac.ke/" rel='noreferrer' target='_blank'><img src={zetech} alt="KEMU" /> </a>
                </div>
            </div>

            <div className="client-row">
                <div className="client-col1" data-aos = "flip-down">
                <a href="https://www.kemu.ac.ke/" rel='noreferrer' target='_blank'><img src={kemu} alt="KEMU" /> </a>
                                       
                </div>
                <div className="client-col1" data-aos = "flip-down">
                <a href="https://aiesec.org/" rel='noreferrer' target='_blank'><img src={aiesec} alt="AIESEC" /></a>
                    
                </div>
                <div className="client-col1" data-aos = "flip-down">   
                    <a href="https://www.kim.ac.ke/" rel='noreferrer' target='_blank'><img src={kim} alt="KIM College" /></a>
                </div>
            </div>

            <div className="client-row">
                <div className="client-col1" data-aos = "flip-down">
                    <a href="https://www.inspiredresource.co.ke/" rel='noreferrer' target='_blank'><img src={inspired} alt="inspired resource" /></a>                                       
                </div>
                <div className="client-col1" data-aos = "flip-down">
                    <a href="http://kawbo.org/" rel='noreferrer' target='_blank'><img src={client8} alt="kawbo" /></a>                    
                </div>
                <div className="client-col1" data-aos = "flip-down">  
                    <a href="https://meru.go.ke/" rel='noreferrer' target='_blank'><img src={meru} alt="Meru County" /></a>                                      
                </div>
            </div>
            <div className="client-row">
                <div className="client-col1" data-aos = "flip-down">
                    <a href="https://www.facebook.com/MCKEmbakasichurch/" rel='noreferrer' target='_blank'><img src={mck} alt="" /></a>                                        
                </div>
                <div className="client-col1" data-aos = "flip-down">
                    <a href="https://mod.go.ke/" rel='noreferrer' target='_blank'><img src={mod} alt="" /></a>                    
                </div>
                <div className="client-col1" data-aos = "flip-down">
                    <a href="https://hymnletsdanceacademy.co.ke/" rel='noreferrer' target='_blank'><img src={hymnlets} alt="Hymnlets Dance & Arts Academy" /></a>                                        
                </div>
            </div>
        </section>
        <div class="footer">
          <p>All rights reserved | <b>Sharepoint Training and Consultancy Ltd © 2024</b></p>
        </div>
    </div>
  )
}

export default Home;