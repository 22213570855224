import React from 'react';
import './inclusivity.css';
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';

function Inclusivity() {
    const navigate = useNavigate();
    const handleNavigate = () => {
      navigate('/');
    }
  return (    
    <div className="inclusivity">
        <section id="about-desc">  
            <div className="about-desc-cover">
                <h1>DIVERSITY & INCLUSION</h1>
                <h2>Is Your Organization PWD-Compliant?</h2>
                <p>
                    Creating an inclusive workplace is essential in today’s business environment. Is your organization aligned with the Persons with Disabilities (PWD) Act, 2003?
                </p>
                <div className="home-row">
                    <div className="about-desc-col" data-aos = "flip-right">
                    
                        <h2><span class="material-symbols-outlined">balance</span> Legal Framework</h2>
                        <ul>
                        The PWD Act, 2003 governs the rights of individuals with disabilities in Kenya, mandating:
                            <li>5% Job Reservation: Employers must allocate 5% of roles to qualified PWDs (Section 12).</li>
                            <li>Non-Discrimination: Fair treatment in hiring, promotion, and retention (Section 13).</li>
                            <li>Accessibility: Public buildings and facilities must be accessible to PWDs (Section 21).</li>
                        </ul>
                    </div>

                    <div className="about-desc-col" data-aos = "flip-right">
                        
                        <h2><span class="material-symbols-outlined">policy</span> Why Update Your PWD Policy?</h2>                    
                        <ul>
                        A compliant policy helps your organization:
                            <li><b>Stay Legal:</b> Align with the PWD Act, 2003 and its amendments.</li>
                            <li><b>Foster Inclusivity:</b> Promote diversity and equal opportunities.</li>
                            <li><b>Boost CSR:</b> Enhance your reputation as a socially responsible employer.</li>
                        </ul>
                    </div>

                    <div className="about-desc-col" data-aos = "flip-right">
                        
                        <h2><span class="material-symbols-outlined">accessible</span> Benefits of Inclusivity</h2>                    
                        <ul>
                        The Kenyan government offers incentives for PWD-friendly businesses, including:
                            <li><b>Tax Rebates</b> on income for hiring PWDs.</li>
                            <li><b>Duty Waivers</b> on specialized equipment for PWDs.</li>
                            <li><b>AGPO Preferences</b>  in public procurement.</li>
                            <li><b>Accessibility Grants</b> for workplace adjustments.</li>
                        </ul>
                    </div>
                </div>
                <span className='inclusivity-contact'><b><Link to="../#contact" id='btn'>Contact</Link> us today to schedule a consultation.</b></span>
                <button className='back-btn' onClick={handleNavigate}>Back Home</button> 
            </div>         
        </section>
    </div>
  )
}

export default Inclusivity;